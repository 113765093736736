import headshot from "../elements/valerie.png"
function About () {

    return(
        <div className="flex flex-col lg:flex-row items-center justify-center mt-16 max-w-screen-lg">
            <div className="flex w-3/4 p-5 items-center justify-center drop-shadow-md opacity-90">
                <img src={headshot} alt="valerie" className="rounded-full pointer-events-none" draggable="false"/>
            </div>
            <div className="flex flex-col items-center justify-center w-3/4 p-5">
                <div className="w-full">
                    <svg className="w-full opacity-50" viewBox="0 0 1200 300" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" version="1.1">
                            <path 
                                d="M0 229L40 215.2C80 201.3 160 173.7 240 148.3C320 123 400 100 480 113.7C560 127.3 640 177.7 720 207.7C800 237.7 880 247.3 960 255.5C1040 263.7 1120 270.3 1160 273.7L1200 277L1200 301L1160 301C1120 301 1040 301 960 301C880 301 800 301 720 301C640 301 560 301 480 301C400 301 320 301 240 301C160 301 80 301 40 301L0 301Z"                        fill="#FFFFFF" 
                                stroke-linecap="round" 
                                stroke-linejoin="round"
                                className="fill-[#607274]">
                            </path>
                        </svg>
                </div>
                <div className="flex flex-col p-10  text-white text-center bg-[#607274] bg-opacity-50">
                    <p className="pb-6">
                        As a proud native Houstonian, I have a deep-rooted understanding of the Houston real estate market, its diverse neighborhoods, and the ever-evolving trends that make this city unique. Whether you’re buying, selling, or leasing, my goal is to provide you with the most informed and strategic guidance to make confident, successful decisions in today’s competitive market. With a background in business, I specialize in negotiation, strategic planning, and delivering exceptional results for my clients. I leverage my local expertise and keen market knowledge to help you navigate the complexities of real estate transactions—whether you’re finding your dream home, securing a profitable investment, selling your home, becoming a landlord or in search of a quality apartment. In every interaction, my focus is on your best interests. From the initial consultation to closing the deal, I’m dedicated to providing a seamless experience that ensures your real estate goals are met with professionalism, transparency, and care.                     
                    </p>
                    <p className="pb-6">
                        When I’m not helping clients achieve their real estate dreams, you’ll find me staying active by playing pickleball, learning French, planning my next trip, curled up with a good book or hanging out with my energetic Shiba Inu! Whether you're new to Houston or a long-time resident, I’d love to help you with your next real estate journey. Let me put my expertise and passion for Houston real estate to work for you, let’s connect!                    
                    </p>
                </div>
                <div className="w-full">
                    <svg className="w-full opacity-50 transform rotate-180" viewBox="0 0 1200 300" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" version="1.1">
                        <path 
                            d="M0 229L40 215.2C80 201.3 160 173.7 240 148.3C320 123 400 100 480 113.7C560 127.3 640 177.7 720 207.7C800 237.7 880 247.3 960 255.5C1040 263.7 1120 270.3 1160 273.7L1200 277L1200 301L1160 301C1120 301 1040 301 960 301C880 301 800 301 720 301C640 301 560 301 480 301C400 301 320 301 240 301C160 301 80 301 40 301L0 301Z"                        fill="#FFFFFF" 
                            stroke-linecap="round" 
                            stroke-linejoin="round"
                            className="fill-[#607274]">
                        </path>
                    </svg>
                </div>
            </div>
            
        </div>
    )
}

export default About