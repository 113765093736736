import FirmLogo from "../elements/firm-logo.webp"

function Footer () {
    const user_email = process.env.REACT_APP_EMAIL

    return (
        <div className="flex flex-col items-center justify-center pb-5 mt-24 mb-24">
            <div className="pt-5">
                <a href="https://www.instagram.com/val.southern/" target="_blank" rel="noopener noreferrer" className="hover:text-[#7f9699]">
                    <i className="bi bi-instagram pr-4 pl-4 text-5xl"></i>
                </a>
                <a href={`mailto:${user_email}`} className="hover:text-[#7f9699]">
                    <i className="bi bi-send pr-4 pl-4 text-5xl"></i>
                </a>
            </div>
            <div className="h-24 pt-10 pb-5">
                <img src={FirmLogo} alt="firm-logo" className="h-full"></img>
            </div>
        </div>
    )
}

export default Footer