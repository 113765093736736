function Qualifications () {

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-start justify-center">
                <p className="font-bold">Renter Qualification Requirements</p>
                <p className="text-[1rem] mb-3">Most properties in the Houston require all of the following to be approved:</p>
                    <ul className="list-disc pl-7">
                        <li className="text-[1rem]">Credit Score (600 and above)</li>
                        <li className="text-[1rem]">Rental History (no evictions, broken leases or unpaid debts)</li>
                        <li className="text-[1rem]">Background (no misdemeanors or felonies)</li>
                        <li className="text-[1rem]">Income (3x the monthly rent before taxes)</li>
                    </ul>
                <p className="font-bold mt-3">DISCLAIMER</p>
                <p className="text-[1rem] mb-3">Please note that I am unable to help with the following situations:</p>
                <ul className="list-disc pl-7">
                    <li className="text-[1rem]">Section 8 Housing</li>
                    <li className="text-[1rem]">Second Chance Leasing</li>
                    <li className="text-[1rem]">Airbnb</li>
                </ul>
            </div>
            <div className="flex flex-col items-center justify-center mt-5 text-center">
                <p className="text-[1rem] font-bold">I will not be your best resource if you do not meet the above requirements.</p>
            </div>   
        </div>
    )
}

export default Qualifications