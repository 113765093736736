function Background () {

    return (
        <>
            <svg 
                id="visual-2" 
                viewBox="0 0 900 600" 
                width="100%" 
                height="100%" 
                preserveAspectRatio="xMidYMid slice" version="1.1">
                    <path 
                        d="M0 190L30 188.3C60 186.7 120 183.3 180 234.7C240 286 300 392 360 394.5C420 397 480 296 540 268.8C600 241.7 660 288.3 720 316.3C780 344.3 840 353.7 870 358.3L900 363L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z" 
                        fill="#DED0B6" 
                        stroke-linecap="round" 
                        stroke-linejoin="miter">
                    </path>
            </svg>
            <svg 
                id="visual-1" 
                viewBox="0 0 900 600" 
                width="100%" 
                height="100%" 
                preserveAspectRatio="xMidYMid slice" version="1.1">
                    <path 
                        d="M0 420L50 364.7C100 309.3 200 198.7 300 173.2C400 147.7 500 207.3 600 237.3C700 267.3 800 267.7 850 267.8L900 268L900 601L850 601C800 601 700 601 600 601C500 601 400 601 300 601C200 601 100 601 50 601L0 601Z" 
                        fill="#B2A59B" 
                        stroke-linecap="round" 
                        stroke-linejoin="miter">
                    </path>
            </svg>
        </>
    )
}

export default Background