import Footer from "./components/Footer";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Background from "./components/Background";

import { Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <div className="flex flex-col items-center justify-center">
      <Navbar />
      <Routes>
        <Route path="/home/" element={<Main />} />
        <Route path="/about/" element={<About />} />
        <Route path="*" element={<Navigate to="/home/" replace />} />
      </Routes>
      <Footer />
      <Background />
    </div>
  );
}

export default App;
